.select-field {
  background-color: transparent;
  .select-field__label {
    font-size: 12px;
    color: #616163;
    padding: 8px 15px 3px 0px;
  }

  &__select {
    width: 100%;
  }
}
