.checkboxStrategy-Formula :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1ca6b2 !important;
  border-color: #1ca6b2 !important;
}

.checkboxStrategy-Contract :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #805cff !important;
  border-color: #805cff !important;
}

.checkboxStrategy-Spot :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f01d82 !important;
  border-color: #f01d82 !important;
}

.checkboxStrategy-NOF :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f2c94c !important;
  border-color: #f2c94c !important;
}

.checkboxStrategy-Financial-derivative
  :global
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: #00b5f2 !important;
  border-color: #00b5f2 !important;
}

.checkboxStrategy-Frozen-product-usage
  :global
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: #fc5d36 !important;
  border-color: #fc5d36 !important;
}

.checkboxStrategy-Hedged :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #006df2 !important;
  border-color: #006df2 !important;
}

.checkboxStrategy-Exposed :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FF9A3D !important;
  border-color: #FF9A3D !important;
}

.checkboxStrategy-Fat :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #545B72 !important;
  border-color: #545B72 !important;
}

.checkboxStrategy-Lean :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #AB2424 !important;
  border-color: #AB2424 !important;
}

.checkboxPriceOption-fmg_price :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0B1435 !important;
  border-color: #0B1435 !important;
}

.checkboxPriceOption-usda_fat :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #545B72 !important;
  border-color: #545B72 !important;
}

.checkboxPriceOption-usda_lean :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #AB2424 !important;
  border-color: #AB2424 !important;
}

.checkboxPriceOption-usda_50CL :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #545B72B3 !important;
  border-color: #545B72B3 !important;
}

.checkboxPriceOption-usda_65CL :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #545B7280 !important;
  border-color: #545B7280 !important;
}

.checkboxPriceOption-usda_78CL :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #AB2424B3 !important;
  border-color: #AB2424B3 !important;
}

.checkboxPriceOption-usda_90CL :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #AB242480 !important;
  border-color: #AB242480 !important;
}

.checkboxPriceOption-mecardo_price :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fcc736 !important;
  border-color: #fcc736 !important;
}

.checkboxPriceOption-mla_price :global .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fcef36 !important;
  border-color: #fcef36 !important;
}

.headerSwitch {
  position: absolute;
  top: 0px;
  /* z-index: 1; */
}

.headerSwitch[data-collapse="true"] {
  transform: translate(20px, 10px);
}

.entitySelector {
  display: flex;
  align-items: center;
}

.entityColor {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-right: 5px;
}