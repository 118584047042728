.order-history-page {
  .mt-checkbox {
    cursor: pointer;

    input {
      cursor: pointer;
    }
  }

  .export-list-orders-container {
    .btn.btn-info {
      &:disabled {
        background-color: #C5CEE0;
        border-color: #C5CEE0;
        cursor: not-allowed;
      }
    }
  }
}

.order-history {
  .filters {
    .po-filter {
      display: flex;
      align-items: center;
      gap: 10px;

      select {
        height: 40px;
      }
      button{
        height: 40px; 
        border-radius: 6px;
      }

      .chakra-input__group {
        flex: 1;
        margin: 0 4px 0 15px;

        input {
          border-left-width: .1px;
        }
      }
    }

    .chakra-menu__menu-button {
      text-align: left;
      margin: 0 4px;
      height: 40px;
    }
  }

  .result-container {
    padding: 29px;
    border-radius: 6px;
    background: #FAFAFA;
    box-shadow: 0px 4px 6px -1px rgba(45, 55, 72, 0.1), 0px 2px 4px -1px rgba(45, 55, 72, 0.06);
  }
}