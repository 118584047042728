.ant-popover-content {
  .ant-btn-sm {
    float: right;
  }
  .ant-btn-sm.ant-btn-primary {
    float: none;
  }
}

.ant-picker-input > input:disabled {
  color: #9fa6ad;
  font-weight: 500;
}

// File Uploader for Food Safety & Quality
.file-uploader {
  .ant-upload {
    height: auto;

    .ant-upload-btn,
    .ant-upload-drag-container {
      display: block;
      height: auto;
    }

    .ant-upload-btn {
      padding: 0;
    }
  }
}

//style bundle css
.form-control {
  display: block;
  width: 100%;
  padding: 0.65rem 1.25rem;
  font-size: 12px;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.full-width {
  width: 100%;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
}
.pending-order-page .req-field.actions button {
  border-width: 1px;
  width: 32px;
  height: 32px;
  min-width: auto;
}
.table tbody + tbody {
  border-top: 2px solid #e9ecef;
}

input.date-time__input:disabled {
  background: #f5f6f9;
  border-color: transparent;
}
.tab-btn:is([aria-selected='true'], [data-selected])[data-orientation='horizontal'] .reporting-tab {
  color: var(--chakra-colors-nav-active);
  box-shadow: inset 0 0 0px 1px var(--chakra-colors-nav-active);
  --indicator-color: transparent;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.email-doc-builder table
{
  margin-bottom: 10px;
}
.documents-queue-view-modal .main .attachment-pane .left-top .chakra-avatar {
  margin-right: 8px;
}
.chakra-dialog__header
{
  border-radius: 5px 5px 0px 0px;
  background-color:#f7fafc;
  margin-bottom: 10px;
}
.cm-theme-light, .cm-theme-dark{
  width: 100%;;
}
.document-dropzone {
  border: 2px dashed #2c333d;
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  font-weight: 500;
  font-size: 24px;
  margin: 20px;
  height: 150px;
  width: 300px;
  cursor: copy;
}

.date-range-picker {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}